/*
 * Add service specific styles here in case it is unavoidable.
 * Please prefer using atomic styles of the UIKIT instead!
 */
.StarterTemplate {
}

.mouse-pointer {
    cursor: pointer;
}

.device-state-vin {
    white-space: nowrap;
}

.device-state-vin-inner {
    display: inline-block;
    vertical-align: top;
}

.white-space-render {
    white-space: pre
}

.padding-top-bottom-2em {
    padding-top: 2em;
    padding-bottom: 2em;
}

.fileDownloadIcon:hover {
    cursor: pointer;
}